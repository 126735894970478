.continue-with-google {
    border: 1px solid #492A99 !important;
    border-radius: 16px;
    background-color: #fff !important;
    color: #1D1C1B !important;
    font-weight: 500;
    word-wrap: break-word;
    width: 100%;
    padding: 14px;
}

.blurred {
    /* Blur effect */
    filter: blur(8px);
    -webkit-filter: blur(8px);
  
    /* Other styles to prevent interaction */
    pointer-events: none;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
}

.blurred2 {
    /* Blur effect */
    filter: blur(9px);
    -webkit-filter: blur(9px);
  
    /* Other styles to prevent interaction */
    pointer-events: none;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
}

.floating-text {
    display: inline-block;
    position: relative;
    animation: floatText 2s ease-in-out infinite;
}

@keyframes floatText {
    0% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
    100% { transform: translateY(0); }
}